import React, { useEffect, useState } from "react";
import CardWrap from "../Common/CardWrap";
import { useForm, Controller } from "react-hook-form";
import { companyService } from "../../Services/CompanyService";
import { obligationService } from "../../Services/ObligationService";
import ErrorMsg from "../Common/ErrorMsg";
import Loader from "../Common/Loader";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const ObligationUpdate = ({ match }) => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [obligation, setObligation] = useState(null);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    Promise.all([
      obligationService.getObligationById(match.params.id),
      companyService.getCompanies(),
    ])
      .then((res) => {
        setObligation(res[0].data.data);
        setCompanies(
          res[1].data.data.map((i) => ({ value: i.id, label: i.name }))
        );
      })
      .catch((err) => console.log(err));
  }, [match.params.id]);

  const onSubmit = (data) => {
    obligationService
      .updateObligation({
        id: match.params.id,
        totalAmount: Number(data.totalAmount),
        date: new Date(data.date),
      })
      .then((res) =>
        res.data.success
          ? alert("Uredili ste dugovanje!")
          : alert("Dogodila se greška!")
      )
      .catch((err) => console.log(err.response));
  };

  return (
    <CardWrap title={translator("ObligationUpdateHeader")}>
      {companies.length === 0 || obligation === null ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label>{translator("RequiredField")}</label>
              <Controller
                as={Select}
                control={control}
                isDisabled={true}
                options={companies}
                name="companyId"
                placeholder={translator("RequiredField")}
                defaultValue={{
                  label:
                    companies.length && obligation.companyId !== 0
                      ? companies.filter(
                          (x) => x.value === obligation.companyId
                        )[0].label
                      : "",
                  value: companies.length !== 0 ? obligation.companyId : "",
                }}
              />
            </div>
            <div className="form-group">
              <label>{translator("ObligationFormAmount")}</label>
              <input
                name="totalAmount"
                type="text"
                className="form-control"
                ref={register({ required: true })}
                defaultValue={obligation.totalAmount}
              />
              {errors.totalAmount && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("ObligationFormDate")}</label>
              <Controller
                as={DatePicker}
                control={control}
                className="form-control"
                valueName="selected"
                autocomplete="off"
                dateFormat="dd.MM.yyyy"
                name="date"
                rules={{ required: true }}
                defaultValue={new Date(obligation.date)}
              />
              {errors.companyId && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
          </div>
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => history.goBack()}
            >
              {translator("ButtonCancel")}
            </button>
            &nbsp;
            <button className="btn btn-primary" type="submit">
              {translator("ButtonSave")}
            </button>
          </div>
        </form>
      )}
    </CardWrap>
  );
};

export default ObligationUpdate;
