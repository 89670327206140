import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "../../Resources/Styles/Sidebar.css";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const { isActiveMenu } = useContext(GlobalContext);
  const { t: translator } = useTranslation();

  const handleAdministrationClick = () => {
    if (
      document.getElementById("submenuInvoicesIssuedReport") &&
      document
        .getElementById("submenuInvoicesIssuedReport")
        .classList.contains("show")
    ) {
      document.getElementById("reports").click();
    }
    if (
      document.getElementById("submenuAccounting") &&
      document.getElementById("submenuAccounting").classList.contains("show")
    ) {
      document.getElementById("accounting").click();
    }
  };
  return (
    <nav id="sidebar" className={isActiveMenu ? "" : "active"}>
      <ul className="list-unstyled components my-first-step">
        <li className="">
          <NavLink className="menu-item" exact to="/">
            <i
              className="pe-7s-home"
              style={{ fontWeight: "600", fontSize: 25 }}
            ></i>
            &nbsp; {translator("MenuHome")}
          </NavLink>
        </li>
        <li className="">
          <NavLink className="menu-item" to="/companies">
            <i
              className="pe-7s-portfolio"
              style={{ fontWeight: "600", fontSize: 25 }}
            ></i>
            &nbsp; {translator("MenuCompany")}
          </NavLink>
        </li>
        <li className="">
          <NavLink className="menu-item" to="/users">
            <i
              className="pe-7s-users"
              style={{ fontWeight: "600", fontSize: 25 }}
            ></i>
            &nbsp; {translator("MenuUsers")}
          </NavLink>
        </li>
        <li className="">
          <NavLink className="menu-item" to="/invoices">
            <i
              className="pe-7s-note2"
              style={{ fontWeight: "600", fontSize: 25 }}
            ></i>
            &nbsp; {translator("MenuInvoices")}
          </NavLink>
        </li>
        <li className="">
          <NavLink className="menu-item" to="/cars">
            <i
              className="pe-7s-car"
              style={{ fontWeight: "600", fontSize: 25 }}
            ></i>
            &nbsp; {translator("MenuCars")}
          </NavLink>
        </li>
        <li>
          <a
            href="#pageSubmenu9"
            data-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle collapsed"
            id="administration"
            onClick={handleAdministrationClick}
          >
            <i
              className="pe-7s-tools"
              style={{ fontWeight: "600", fontSize: 25 }}
            ></i>
            &nbsp; {translator("MenuAdministration")}
          </a>
          <ul className="collapse list-unstyled" id="pageSubmenu9">
            <li style={{ paddingLeft: 30 }}>
              <NavLink className="administrationItems" to="/obligations">
                {translator("MenuObligations")}
              </NavLink>
            </li>
            <li style={{ paddingLeft: 30 }}>
              <NavLink className="administrationItems" to="/blackMarkets">
                {translator("MenuBlackMarket")}
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
