import { invoiceUrl } from "../Config/Url";
import axios from "axios";

export const invoiceService = {
  getInvoices,
  deleteInvoice,
  addInvoice,
  getInvoice,
  updateInvoice,
};

async function getInvoices() {
  return axios
    .get(invoiceUrl)
    .then((res) => res)
    .catch((err) => err.response);
}

async function deleteInvoice(id) {
  return axios
    .delete(`${invoiceUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function addInvoice(submitData, data) {
  return axios
    .post(invoiceUrl, {
      invoiceNumber: submitData.invoiceNumber,
      invoiceDate: new Date(submitData.invoiceDate),
      companyId: Number(submitData.companyId.value),
      userId: Number(localStorage.getItem("userId")),
      totalAmount: data.reduce(
        (sum, { amount, quantity }) => sum + amount * quantity,
        0
      ),
      isPaid: false,
      isArrived: false,
      isExported: false,
      data,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
async function getInvoice(id) {
  return axios
    .get(`${invoiceUrl}/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

async function updateInvoice(id, submitData) {
  return axios
    .put(`${invoiceUrl}/${id}`, {
      isPaid: submitData.isPaid,
      isArrived: submitData.isArrived,
      isExported: submitData.isExported,
    })
    .then((res) => res)
    .catch((err) => err.response);
}
