import { useEffect, useState } from "react";
import { blackMarketService } from "../../Services/BlackMarketService";
import BootstrapTable from "react-bootstrap-table-next";
import CardWrap from "../Common/CardWrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Common/Loader";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as dateHelper from "../../Helpers/DateHelper";
import { useTranslation } from "react-i18next";

const BlackMarketList = () => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [blackMarkets, setblackMarkets] = useState([]);

  useEffect(() => {
    blackMarketService
      .getblackMarkets()
      .then((res) => {
        setblackMarkets(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "invoiceDate",
      text: `${translator("BlackMarketTableDate")}`,
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "companyName",
      text: `${translator("BlackMarketTableCompany")}`,
      sort: true,
    },
    {
      dataField: "amount",
      text: `${translator("BlackMarketTableAmount")}`,
      sort: true,
    },
  ];
  const handleToAdd = () => {
    history.push("/add-blackmarket");
  };
  const rowEvents = {
    onClick: (e, row) => {
      e.preventDefault();
      history.push(`/update-blackmarket/${row.id}`);
    },
  };
  function dateFormatter(cell, row) {
    return dateHelper.formatUtcToDate(cell);
  }
  return (
    <CardWrap title={translator("BlackMarketOverview")}>
      <ToolkitProvider
        keyField="id"
        data={blackMarkets}
        columns={columns}
        search
      >
        {(props) => (
          <>
            <SearchBar
              {...props.searchProps}
              placeholder={translator("Search")}
            />{" "}
            <span style={{ float: "right" }}>
              <Button variant="primary" onClick={handleToAdd}>
                {translator("BlackMarketAddButton")}
              </Button>
            </span>{" "}
            <hr />
            {isLoading ? (
              <Loader />
            ) : (
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
                noDataIndication={translator("NoDataIndication")}
                rowEvents={rowEvents}
                hover
              />
            )}
          </>
        )}
      </ToolkitProvider>
    </CardWrap>
  );
};

export default BlackMarketList;
