export const apiUrl = `http://rpm-development.com:5002/api`;
export const staticUrl = `http://rpm-development.com:5002/images/`;

export const todoUrl = `${apiUrl}/todo`;
export const companyUrl = `${apiUrl}/company`;
export const userUrl = `${apiUrl}/user`;
export const authUrl = `${apiUrl}/auth`;
export const carUrl = `${apiUrl}/car`;
export const invoiceUrl = `${apiUrl}/invoice`;
export const transactionUrl = `${apiUrl}/obligationTransactions`;
export const obligationUrl = `${apiUrl}/obligation`;
export const resourceUrl = `${apiUrl}/resources`;
export const langUrl = `${apiUrl}/language`;
export const blackMarketUrl = `${apiUrl}/blackMarket`;
export const carImageUrl = `${apiUrl}/carImage`;
