import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import CardWrap from "../Common/CardWrap";
import ErrorMsg from "../Common/ErrorMsg";
import { email } from "../../Config/Regex";
import { companyService } from "../../Services/CompanyService";
import Loader from "../Common/Loader";
import { useTranslation } from "react-i18next";

const CompanyUpdate = ({ match }) => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [company, setCompany] = useState(null);
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    companyService
      .getCompanyById(match.params.id)
      .then((res) => setCompany(res.data.data))
      .catch((err) => console.log(err));
  }, [match.params.id]);

  const onSubmit = (data) => {
    companyService
      .updateCompany({
        id: match.params.id,
        name: data.name,
        address: data.address,
        city: data.city,
        postNumber: data.postNumber,
        customerCode: data.customerCode,
        email: data.email,
        phone: data.phone,
      })
      .then((res) => {
        res.data.success
          ? alert("Uspješno ste uredili tvrtku")
          : alert("Dogodila se greška");
      })
      .catch((err) => console.log(err));
  };

  return (
    <CardWrap title={translator("CompanyUpdateHeader")}>
      {company === null ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label>{translator("ComapnyFormName")}</label>
              <input
                type="text"
                name="name"
                className="form-control"
                ref={register({ required: true })}
                defaultValue={company.name}
              />
              {errors.name && <ErrorMsg text={translator("RequiredField")} />}
            </div>
            <div className="form-group">
              <label>{translator("CompanyFormEmail")}</label>
              <input
                type="text"
                name="email"
                className="form-control"
                ref={register({ required: true, pattern: email })}
                defaultValue={company.email}
              />
              {errors.email && errors.email.type === "required" && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
              {errors.email && errors.email.type === "pattern" && (
                <ErrorMsg text="Molimo unesite ispravnu email adresu!" />
              )}
            </div>
            <div className="form-group">
              <label>{translator("CompanyFormPhone")}</label>
              <input
                type="text"
                name="phone"
                className="form-control"
                ref={register({ required: true })}
                defaultValue={company.phone}
              />
              {errors.phone && <ErrorMsg text={translator("RequiredField")} />}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label>{translator("CompanyFormCity")}</label>
              <input
                type="text"
                name="city"
                className="form-control"
                ref={register({ required: true })}
                defaultValue={company.city}
              />
              {errors.city && <ErrorMsg text={translator("RequiredField")} />}
            </div>
            <div className="form-group">
              <label>{translator("CompanyFormAddress")}</label>
              <input
                type="text"
                name="address"
                className="form-control"
                ref={register({ required: true })}
                defaultValue={company.address}
              />
              {errors.address && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("CompanyFormPostNumber")}</label>
              <input
                type="text"
                name="postNumber"
                className="form-control"
                ref={register({ required: true })}
                defaultValue={company.postNumber}
              />
              {errors.postNumber && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("CompanyFormCode")}</label>
              <input
                type="text"
                name="customerCode"
                className="form-control"
                ref={register({ required: true })}
                defaultValue={company.customerCode}
              />
              {errors.customerCode && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
          </div>
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => history.goBack()}
            >
              {translator("ButtonCancel")}
            </button>
            &nbsp;
            <button className="btn btn-primary" type="submit">
              {translator("ButtonSave")}
            </button>
          </div>
        </form>
      )}
    </CardWrap>
  );
};

export default CompanyUpdate;
