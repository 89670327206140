import { useEffect, useState } from "react";
import { invoiceService } from "../../Services/InvoiceService";
import BootstrapTable from "react-bootstrap-table-next";
import CardWrap from "../Common/CardWrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button } from "react-bootstrap";
import Loader from "../Common/Loader";
import { convertToPdf } from "../../Services/PDFService/invoiceToPdf.service";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import * as dateHelper from "../../Helpers/DateHelper";

const InvoiceList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const { t: translator } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    invoiceService
      .getInvoices()
      .then((res) => {
        setInvoices(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleToAdd = () => {
    history.push("/add-invoice");
    //convertToPdf.handlePrint();
    //alert("");
  };

  function dateFormatter(cell, row) {
    return dateHelper.formatUtcToDate(row.invoiceDate);
  }

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "invoiceNumber",
      text: "Broj računa",
    },
    {
      dataField: "invoiceDate",
      text: "Datum",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "companyName",
      text: "Tvrtka",
      sort: true,
    },
    {
      dataField: "totalAmount",
      text: "Iznos",
      sort: true,
    },
    {
      dataField: "isPaid",
      text: "Plaćeno",
      sort: true,
    },
  ];
  const rowEvents = {
    onClick: (e, row) => {
      e.preventDefault();
      history.push(`/update-invoice/${row.id}`);
    },
  };

  return (
    <CardWrap title="Pregled računa">
      <ToolkitProvider keyField="id" data={invoices} columns={columns} search>
        {(props) => (
          <>
            <SearchBar {...props.searchProps} placeholder="Pretraga" />
            <span style={{ float: "right" }}>
              <Button variant="primary" onClick={handleToAdd}>
                {translator("InvoiceAddButton")}
              </Button>
            </span>
            <hr />
            {isLoading ? (
              <Loader />
            ) : (
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
                noDataIndication="Trenutno nema računa u bazi podataka."
                rowEvents={rowEvents}
                hover
              />
            )}
          </>
        )}
      </ToolkitProvider>
    </CardWrap>
  );
};

export default InvoiceList;
