import React, { useEffect, useState } from "react";
import CardWrap from "../Common/CardWrap";
import { useForm, Controller } from "react-hook-form";
import { companyService } from "../../Services/CompanyService";
import { obligationService } from "../../Services/ObligationService";
import ErrorMsg from "../Common/ErrorMsg";
import Loader from "../Common/Loader";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const ObligationAdd = () => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    companyService
      .getCompanies()
      .then((res) =>
        setCompanies(res.data.data.map((i) => ({ value: i.id, label: i.name })))
      )
      .catch((err) => console.log(err.response));
  }, []);

  const onSubmit = (data) => {
    obligationService
      .addObligation({
        companyId: Number(data.companyId.value),
        totalAmount: Number(data.totalAmount),
        date: new Date(data.date),
      })
      .then((res) =>
        res.data.success
          ? alert("Dodali ste novo dugovanje!")
          : alert("Dogodila se greška!")
      )
      .catch((err) => console.log(err.response));
  };

  return (
    <CardWrap title={translator("ObligationAddHeader")}>
      {companies.length === 0 ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label>{translator("ObligationFormCompany")}</label>
              <Controller
                as={Select}
                control={control}
                options={companies}
                name="companyId"
                rules={{ required: true }}
                placeholder={translator("RequiredField")}
              />
              {errors.companyId && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("ObligationFormAmount")}</label>
              <input
                name="totalAmount"
                type="text"
                className="form-control"
                ref={register({ required: true })}
              />
              {errors.totalAmount && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("ObligationFormDate")}</label>
              <Controller
                as={DatePicker}
                control={control}
                className="form-control"
                valueName="selected"
                autocomplete="off"
                dateFormat="dd.MM.yyyy"
                name="date"
                rules={{ required: true }}
              />
              {errors.companyId && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
          </div>
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => history.goBack()}
            >
              {translator("ButtonCancel")}
            </button>
            &nbsp;
            <button className="btn btn-primary" type="submit">
              {translator("ButtonSave")}
            </button>
          </div>
        </form>
      )}
    </CardWrap>
  );
};

export default ObligationAdd;
