import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import CardWrap from "../Common/CardWrap";
import ErrorMsg from "../Common/ErrorMsg";
import { invoiceService } from "../../Services/InvoiceService";
import Loader from "../Common/Loader";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router";
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";
import { companyService } from "../../Services/CompanyService";
import { convertToPdf } from "../../Services/PDFService/invoiceToPdf.service";

const InvoiceUpdate = ({ match }) => {
  const { t: translator } = useTranslation();
  const history = useHistory();

  const [companies, setCompanies] = useState([]);
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [data, setData] = useState(null);
  const [items, setItems] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    Promise.all([
      invoiceService.getInvoice(match.params.id),
      companyService.getCompanies(),
    ])
      .then((res) => {
        setData(res[0].data.data);
        setItems(res[0].data.items);
        setCompanies(
          res[1].data.data.map((i) => ({ value: i.id, label: i.name }))
        );
      })
      .catch((err) => console.log(err));
  }, [match.params.id]);

  const columns = [
    {
      dataField: "name",
      text: `${translator("Name")}`,
    },
    {
      dataField: "regNum",
      text: `${translator("RegNum")}`,
    },
    {
      dataField: "chasisNum",
      text: `${translator("ChasisNum")}`,
    },
    {
      dataField: "engineNum",
      text: `${translator("EngineNum")}`,
    },
    {
      dataField: "engineSize",
      text: `${translator("EngineSize")}`,
    },
    {
      dataField: "kw",
      text: `${translator("kW")}`,
    },
    {
      dataField: "color",
      text: `${translator("Color")}`,
    },
    {
      dataField: "amount",
      text: `${translator("Amount")}`,
    },
    {
      dataField: "quantity",
      text: `${translator("Quantity")}`,
    },
  ];
  const onSubmit = (data) => {
    console.log("data", data);
    //return;
    invoiceService
      .updateInvoice(match.params.id, data)
      .then((res) =>
        res.data.success
          ? alert("Uredili ste račun!")
          : alert("Dogodila se greška!")
      )
      .catch((err) => console.log(err.response));
  };

  const exportToPdf = (data, items) => {
    //let base64QR = document.getElementById("qrCode").toDataURL();
    convertToPdf.handlePrint(data, items, translator);
  };
  return (
    <CardWrap title={translator("UserUpdateHeader")}>
      {companies.length === 0 || data === null ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-lg-3">
            <div className="form-group">
              <label>{translator("InvoiceNumber")}</label>
              <input
                type="text"
                disabled
                name="invoiceNumber"
                ref={register({ required: true })}
                defaultValue={data.invoiceNumber}
                className="form-control"
              />
              {errors.invoiceNumber && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("InvoiceFormDate")}</label>
              <Controller
                as={DatePicker}
                control={control}
                disabled
                className="form-control"
                valueName="selected"
                autocomplete="off"
                dateFormat="dd.MM.yyyy"
                name="invoiceDate"
                rules={{ required: true }}
                defaultValue={new Date(data.invoiceDate)}
              />
              {errors.invoiceDate && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("InvoiceCompany")}</label>
              <Controller
                as={Select}
                control={control}
                options={companies}
                rules={{ required: true }}
                name="companyId"
                placeholder={translator("CompanyDropdownOption")}
                defaultValue={{
                  label:
                    companies.length && data.companyId !== 0
                      ? companies.filter((x) => x.value === data.companyId)[0]
                          .label
                      : "",
                  value: companies.length !== 0 ? data.companyId : "",
                }}
                isDisabled={true}
              />
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                name="isPaid"
                ref={register}
                defaultChecked={data.isPaid}
              />

              <label>{translator("InvoiceFormPaid")}</label>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                name="isExported"
                ref={register}
                defaultChecked={data.isExported}
              />
              <label>{translator("InvoiceFormExported")}</label>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                name="isArrived"
                ref={register}
                defaultChecked={data.isArrived}
              />
              <label>{translator("InvoiceFormArrived")}</label>
            </div>
            <div
              className="container-fluid"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => history.goBack()}
              >
                {translator("ButtonCancel")}
              </button>
              &nbsp;
              <button className="btn btn-primary" type="submit">
                {translator("ButtonSave")}
              </button>
            </div>
          </div>
          <div className="col-lg-9 border-left">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => exportToPdf(data, items)}
            >
              {translator("InvoiceExport")}
            </button>
            <hr />
            <BootstrapTable
              keyField="regNum"
              data={items}
              columns={columns}
              pagination={paginationFactory()}
              //cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
              noDataIndication={translator("NoDataIndication")}
              hide
            />
          </div>
        </form>
      )}
    </CardWrap>
  );
};

export default InvoiceUpdate;
