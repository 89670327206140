import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import CardWrap from "../Common/CardWrap";
import ErrorMsg from "../Common/ErrorMsg";
import Select from "react-select";
import { companyService } from "../../Services/CompanyService";
import { carService } from "../../Services/CarService";
import { carImageService } from "../../Services/CarImageService";
import Loader from "../Common/Loader";
import { useTranslation } from "react-i18next";
import DztImageGalleryComponent from "reactjs-image-gallery";
import { staticUrl } from "../../Config/Url";

const CarUpdate = ({ match }) => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [data, setData] = useState(null);
  const [images, setImages] = useState([]);
  useEffect(() => {
    Promise.all([
      carService.getById(match.params.id),
      companyService.getCompanies(),
      carImageService.getCarImages(match.params.id),
    ])
      .then((res) => {
        setData(res[0].data.data);
        setCompanies(
          res[1].data.data.map((i) => ({ value: i.id, label: i.name }))
        );
        // console.log(res[2].data.data);
        setImages(
          res[2].data.data.map((i) => ({
            url: `${staticUrl}${i.name}`,
            title: i.name,
            thumbUrl: `${staticUrl}${i.name}`,
          }))
        );
      })
      .catch((err) => console.log(err));
  }, [match.params.id]);

  const onSubmit = (data) => {
    console.log(data);
    alert("Tu sam");
    carService
      .updateCar(match.params.id, data)
      .then((res) =>
        res.data.success
          ? alert("Uspješno ste uredili vozilo!")
          : alert("Dogodila se greška!")
      )
      .catch((err) => console.log(err));
  };

  return (
    <CardWrap title={translator("CarUpdateHeader")}>
      {companies.length === 0 || data === null ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label>{translator("CarFormName")}</label>
              <input
                name="name"
                className="form-control"
                type="text"
                ref={register({ required: true })}
                defaultValue={data.name}
              />
              {errors.name && <ErrorMsg text={translator("RequiredField")} />}
            </div>
            <div className="form-group">
              <label>{translator("CarFormRegNum")}</label>
              <input
                name="regNum"
                className="form-control"
                type="text"
                ref={register({ required: true })}
                defaultValue={data.regNum}
              />
              {errors.regNum && <ErrorMsg text={translator("RequiredField")} />}
            </div>
            <div className="form-group">
              <label>{translator("CarFormChasisNum")}</label>
              <input
                name="chasisNum"
                className="form-control"
                type="text"
                ref={register({ required: true })}
                defaultValue={data.chasisNum}
              />
              {errors.chasisNum && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label>{translator("CarFormEngineNum")}</label>
              <input
                name="engineNum"
                className="form-control"
                type="text"
                ref={register({ required: true })}
                defaultValue={data.engineNum}
              />
              {errors.engineNum && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>
            <div className="form-group">
              <label>{translator("CarFormEngineSize")}</label>
              <input
                name="engineSize"
                className="form-control"
                type="text"
                ref={register({ required: true })}
                defaultValue={data.engineSize}
              />
              {errors.engineSize && (
                <ErrorMsg text={translator("RequiredField")} />
              )}
            </div>

            <div className="form-group">
              <label>{translator("CarFormColor")}</label>
              <input
                className="form-control"
                type="text"
                name="color"
                ref={register({ required: true })}
                defaultValue={data.color}
              />
              {errors.color && <ErrorMsg text={translator("RequiredField")} />}
            </div>

            <div className="form-group">
              <label>{translator("CarFormKw")}</label>
              <input
                className="form-control"
                type="text"
                name="kw"
                ref={register({ required: true })}
                defaultValue={data.kw}
              />
              {errors.kw && <ErrorMsg text={translator("RequiredField")} />}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label>{translator("CarFormImages")}</label>
              <input
                className="form-control"
                type="file"
                name="images"
                ref={register()}
                multiple
              />
            </div>
          </div>
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => history.goBack()}
            >
              {translator("ButtonCancel")}
            </button>
            &nbsp;
            <button className="btn btn-primary" type="submit">
              {translator("ButtonSave")}
            </button>
          </div>
          {images.length === 0 || data === null ? (
            ""
          ) : (
            <div>
              {translator("CarFormImages")}
              <DztImageGalleryComponent images={images} />
            </div>
          )}
        </form>
      )}
    </CardWrap>
  );
};

export default CarUpdate;
