import { useEffect, useState } from "react";
import { userService } from "../../Services/UserService";
import BootstrapTable from "react-bootstrap-table-next";
import CardWrap from "../Common/CardWrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Common/Loader";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UserList = () => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    userService
      .getUsers()
      .then((res) => {
        setUsers(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleToAdd = () => {
    history.push("/add-user");
  };

  const rowEvents = {
    onClick: (e, row) => {
      e.preventDefault();
      history.push(`/update-user/${row.id}`);
    },
  };

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "fullName",
      text: `${translator("UserTableFullName")}`,
      sort: true,
    },
    {
      dataField: "username",
      text: `${translator("UserTableUsername")}`,
      sort: true,
    },
    {
      dataField: "companyName",
      text: `${translator("UserTableCompanyName")}`,
      sort: true,
    },
  ];

  return (
    <CardWrap title={translator("UserOverview")}>
      <ToolkitProvider keyField="id" data={users} columns={columns} search>
        {(props) => (
          <>
            <SearchBar
              {...props.searchProps}
              placeholder={translator("Search")}
            />
            <span style={{ float: "right" }}>
              <Button variant="primary" onClick={handleToAdd}>
                {translator("UserAddButton")}
              </Button>
            </span>

            <hr />
            {isLoading ? (
              <Loader />
            ) : (
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
                noDataIndication={translator("NoDataIndication")}
                rowEvents={rowEvents}
                hover
              />
            )}
          </>
        )}
      </ToolkitProvider>
    </CardWrap>
  );
};

export default UserList;
