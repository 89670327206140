import React, { useEffect, useState } from "react";
import CardWrap from "../Common/CardWrap";
import { useForm, Controller } from "react-hook-form";
import ErrorMsg from "../Common/ErrorMsg";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { companyService } from "../../Services/CompanyService";
import { invoiceService } from "../../Services/InvoiceService";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";
import NewItemModal from "./NewItemModal";

const InvoiceAdd = () => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);

  const handleModalSubmit = (data) => {
    setData((prevData) => [...prevData, data]);
    setShow(false);
  };

  const handleHide = () => {
    setShow(false);
  };

  useEffect(() => {
    companyService
      .getCompanies()
      .then((res) =>
        setCompanies(res.data.data.map((i) => ({ value: i.id, label: i.name })))
      )
      .catch((err) => console.log(err.response));
  }, []);

  const onSubmit = (submitData) => {
    invoiceService
      .addInvoice(submitData, data)
      .then((res) =>
        res.data.success
          ? alert("Uspješno dodan račun")
          : alert("Dogodila se greška")
      )
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      dataField: "name",
      text: `${translator("Name")}`,
    },
    {
      dataField: "regNum",
      text: `${translator("RegNum")}`,
    },
    {
      dataField: "chasisNum",
      text: `${translator("ChasisNum")}`,
    },
    {
      dataField: "engineNum",
      text: `${translator("EngineNum")}`,
    },
    {
      dataField: "engineSize",
      text: `${translator("EngineSize")}`,
    },
    {
      dataField: "kw",
      text: `${translator("kW")}`,
    },
    {
      dataField: "color",
      text: `${translator("Color")}`,
    },
    {
      dataField: "amount",
      text: `${translator("Amount")}`,
    },
    {
      dataField: "quantity",
      text: `${translator("Quantity")}`,
    },
  ];

  return (
    <CardWrap title={translator("InvoiceAddHeader")}>
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-lg-3">
          <div className="form-group">
            <label>{translator("InvoiceNumber")}</label>
            <input
              type="text"
              name="invoiceNumber"
              ref={register({ required: true })}
              className="form-control"
            />
            {errors.invoiceNumber && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
          </div>
          <div className="form-group">
            <label>{translator("InvoiceFormDate")}</label>
            <Controller
              as={DatePicker}
              control={control}
              className="form-control"
              valueName="selected"
              autocomplete="off"
              dateFormat="dd.MM.yyyy"
              name="invoiceDate"
              rules={{ required: true }}
            />
            {errors.invoiceDate && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
          </div>
          <div className="form-group">
            <label>{translator("InvoiceCompany")}</label>
            <Controller
              as={Select}
              control={control}
              options={companies}
              rules={{ required: true }}
              name="companyId"
              placeholder={translator("CompanyDropdownOption")}
            />
            {errors.companyId && (
              <ErrorMsg text={translator("RequiredField")} />
            )}
          </div>
        </div>
        <div className="col-lg-9 border-left">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => setShow(true)}
          >
            {translator("NewItem")}
          </button>
          <hr />
          <BootstrapTable
            keyField="regNum"
            data={data}
            columns={columns}
            pagination={paginationFactory()}
            cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
            noDataIndication={translator("NoDataIndication")}
          />
        </div>
        <div
          className="container-fluid"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => history.goBack()}
          >
            {translator("ButtonCancel")}
          </button>
          &nbsp;
          <button className="btn btn-primary" type="submit">
            {translator("ButtonSave")}
          </button>
        </div>
      </form>
      <NewItemModal
        show={show}
        handleHide={handleHide}
        handleModalSubmit={handleModalSubmit}
        translator={translator}
      />
    </CardWrap>
  );
};

export default InvoiceAdd;
