import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { i18nService } from "../i18n";
import { globalService } from "../Services/GlobalService";

const SELECTED_LANGUAGE_ID = "SELECTED_LANGUAGE_ID";
export const GlobalContext = createContext();

const GlobalContextProvider = (props) => {
  const history = useHistory();
  const [globalState, setGlobalState] = useState({
    selectedLanguageId: localStorage.getItem(SELECTED_LANGUAGE_ID)
      ? localStorage.getItem(SELECTED_LANGUAGE_ID)
      : 1,
    updatedResource: null,
  });

  const [translations, setTranslations] = useState();
  const [translationsCache, setTranslationsCache] = useState([]);
  const [i18nConfig, setI18nConfig] = useState(null);

  const [statusModal, setStatusModal] = useState({
    show: false,
    text: "",
    handleHide: () => setStatusModal({ ...setStatusModal, show: false }),
  });

  const changeLanguage = (languageId) => {
    localStorage.setItem(SELECTED_LANGUAGE_ID, languageId);
    setGlobalState({ ...globalState, selectedLanguageId: languageId });
    //history.push("/");
    window.location.reload();
  };

  const updateTranslation = ({ languageId, key, value }) => {
    setGlobalState({
      ...globalState,
      updatedResource: { languageId, key, value },
    });
  };

  const [isActiveMenu, setIsActiveMenu] = useState(true);

  const toggleMenu = () => setIsActiveMenu(!isActiveMenu);

  useEffect(() => {
    const data = translationsCache.find(
      (tc) => tc.lang == globalState.selectedLanguageId
    );
    if (!data) {
      globalService
        .getResourcesByLanguageId(globalState.selectedLanguageId)
        .then((data) => {
          setTranslationsCache([
            ...translationsCache,
            { lang: globalState.selectedLanguageId, translations: data },
          ]);
          setTranslations(data.data);
        })
        .catch((err) => console.log("translation err", err));
    } else {
      setTranslations(data.translations);
    }
  }, [globalState.selectedLanguageId]);

  useEffect(() => {
    const data = translationsCache.find(
      (tc) => tc.lang == globalState.updatedResource.languageId
    );

    if (data) {
      var updatedTranslations = {
        ...data.translations,
        [globalState.updatedResource.key]: globalState.updatedResource.value,
      };

      setTranslationsCache([
        ...translationsCache,
        {
          lang: globalState.selectedLanguageId,
          translations: globalState.updatedTranslations,
        },
      ]);

      if (
        globalState.selectedLanguageId == globalState.updatedResource.languageId
      ) {
        setTranslations(updatedTranslations);
      }
    }
  }, [globalState.updatedResource]);
  useEffect(() => {
    if (translations) {
      i18nService(translations).then((data) => {
        setI18nConfig(data);
      });
    }
  }, [translations]);

  return (
    <GlobalContext.Provider
      value={{
        ...globalState,
        statusModal,
        setStatusModal,
        isActiveMenu,
        toggleMenu,
        changeLanguage,
        updateTranslation,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
