import { useContext } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../Contexts/AuthContext";
import { authService } from "../../Services/AuthService";
import { useHistory } from "react-router";
import logo from "../../Resources/Images/logo.jpg";

const Login = () => {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { setToken } = useContext(AuthContext);

  const onSubmit = (data) => {
    authService
      .login(data.username, data.password)
      .then((res) => {
        console.log("res");
        if (res.data.success) {
          setToken(res.data.token.accessToken);
          localStorage.setItem("token", res.data.token.accessToken);
          localStorage.setItem("isLogged", true);
          localStorage.setItem("username", res.data.userInfo.userName);
          localStorage.setItem("userId", res.data.userInfo.id);
          history.push("/invoices");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 50 }}>
      <Card style={{ width: "25rem" }}>
        <Card.Img variant="top" src={logo} />
        <Card.Body>
          <Card.Title>Prijava</Card.Title>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>Korisničko ime</Form.Label>
              <Form.Control
                name="username"
                type="text"
                placeholder="Unesite korisničko ime"
                ref={register({ required: true })}
              />
              {errors.username && (
                <p className="text-danger">Ovo polje je obavezno!</p>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Lozinka</Form.Label>
              <Form.Control
                name="password"
                type="password"
                placeholder="--Unesite lozinku--"
                ref={register({ required: true })}
              />
              {errors.password && (
                <p className="text-danger">Ovo polje je obavezno!</p>
              )}
            </Form.Group>
            <Button variant="primary" type="submit" block>
              Prijava
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
